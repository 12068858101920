/* eslint-disable react-hooks/rules-of-hooks */
// TODO: this ^ lint rule is catching a true positive - we're conditionally
// calling hooks, which may cause problems.  It *doesn't* cause problems, but we
// should really be doing the safe thing.  Remove this comment and fix the
// underlying issue.  - kh 2024-12-03
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api";
import {
  localStorageCache,
  sessionStorageCache,
} from "../modules/localStorageHelper";

const LAST_ORG_ID_KEY = "lastOrgId";

// Get the current org ID to show on the page.  Pulls from the url first (eg
// /org/123/dashboard), then tries session storage (ie the last org we looked at
// in the current page), then tries local storage (ie the last org we looked at
// in this browser).
export function useCurrentOrgId() {
  // This allows us to inject an orgID in storybook stories.  TODO: this should
  // be provided by a context that's swapped out via a decorator.
  const fixturedOrgId: string | undefined = (window as any)[LAST_ORG_ID_KEY];

  const [orgId, _] = useState(
    useOrgIdFromRoute() ??
      fixturedOrgId ??
      orgIdFromSessionStorage() ??
      orgIdFromLocalStorage(),
  );
  useEffect(() => {
    if (orgId) {
      saveCurrentOrgId(orgId);
    }
  }, [orgId]);

  return orgId;
}

// Get the current org ID, but if it's not available, try to find the first
// available org ID from the backend and save it.
export function useCurrentOrgIdWithFallback() {
  const orgId = useCurrentOrgId();
  if (orgId) {
    return orgId;
  }
  const firstAvailableOrgId = useFirstAvailableOrgId();
  if (firstAvailableOrgId) {
    saveCurrentOrgId(firstAvailableOrgId);
  }
  return firstAvailableOrgId;
}

// Get the current org object to show on the page.  See useCurrentOrgId() for
// how this looks up the org ID.  Returns a standard tanstack query response, so
// get the actual org data (or `undefined` before it's finished loading) with:
// const { data: org } = useCurrentOrg();
export function useCurrentOrg(fallback: boolean = true) {
  const orgId = fallback ? useCurrentOrgIdWithFallback() : useCurrentOrgId();
  const queryResult = useQuery({
    queryFn: () => api.getOrganization({ id: orgId ?? "" }),
    queryKey: ["org", orgId],
    enabled: !!orgId,
    retry: false,
  });
  if (queryResult.error) {
    if (
      queryResult.error instanceof AxiosError &&
      queryResult.error.response?.status === 403
    ) {
      // This is a 403 error, which probably means the user doesn't have access
      // to this org.  In this case, clear the orgId cache so we don't keep
      // trying to load this org.
      clearCurrentOrgId();
    }
  }
  return queryResult;
}

// Create a standard org path from a path and an org ID.  Eg convert "/settings"
// to "/org/123/settings".
export function orgPath(path: string, orgId: string) {
  const cleanPath = path
    // Remove leading slashes so we can pass in either "settings" or "/settings"
    .replace(/^\//, "")

    // If you pass in `/org/123/setting, don't add another /org/123/ - just
    // replace it.
    .replace(/^org\/\d+\/?/, "");

  return `/org/${orgId}/${cleanPath}`;
}

function saveCurrentOrgId(orgId: string) {
  sessionStorageCache.setItem(LAST_ORG_ID_KEY, orgId);
  localStorageCache.setItem(LAST_ORG_ID_KEY, orgId);
}
export function clearCurrentOrgId() {
  sessionStorageCache.removeItem(LAST_ORG_ID_KEY);
  localStorageCache.removeItem(LAST_ORG_ID_KEY);
}

function orgIdFromLocalStorage() {
  const orgId = localStorageCache.getItem(LAST_ORG_ID_KEY);
  return typeof orgId === "string" ? orgId : undefined;
}
function orgIdFromSessionStorage() {
  const orgId = sessionStorageCache.getItem(LAST_ORG_ID_KEY);
  return typeof orgId === "string" ? orgId : undefined;
}
export function useOrgIdFromRoute() {
  return useParams().orgId;
}
export function useFirstAvailableOrgId() {
  const { data } = useQuery({
    queryFn: api.getMe,
    queryKey: ["users", "me"],
  });
  return data?.userOrganizations?.[0]?.organization?.id?.toString();
}
