import Redirect from "../components/Redirect";
import useUser from "../state/useUser";

export function redirectUnless(
  to: string,
  test: ({
    userAuthed,
    userUnauthed,
  }: {
    userAuthed: boolean;
    userUnauthed: boolean;
  }) => boolean,
) {
  return <PropType,>(Wrapped: React.ComponentType<PropType>) => {
    return function RedirectUnless(
      props: PropType & { children?: React.ReactNode },
    ) {
      const userCtx = useUser();
      if (userCtx.userLoading) {
        return null;
      }
      return test(userCtx) ? <Wrapped {...props} /> : <Redirect to={to} />;
    };
  };
}

export const redirectIfAuthed = redirectUnless(
  "/dashboard",
  ({ userUnauthed }) => userUnauthed,
);

export const redirectIfUnauthed = redirectUnless(
  "/",
  ({ userAuthed }) => userAuthed,
);
