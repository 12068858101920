const BASENAME = import.meta.env.BASE_URL.replace(/\/$/, "");
// Convert a mostly-absolute path (like "contracts/whatever" or
// "/contracts/whatever") to an actual, environment-aware absolute path like
// "/app/contracts/whatever" (if appropriate).
export const appHref = (path: string) => {
  if (!path.startsWith("/")) {
    path = "/" + path;
  }
  return BASENAME + path;
};
