import { FileTrigger } from "react-aria-components";
import Button from "./Button";

/**
 * @param {import('react-aria-components').FileTriggerProps} rest
 * @param {Array<File>} files
 * @param children Override default button
 * @param {function} onFilesChange
 */
export default function FileInput({ files, children, onFilesChange, ...rest }) {
  function handleOnSelect(filelist) {
    if (rest.onSelect) {
      rest.onSelect(filelist);
    }
    onFilesChange(Array.from(filelist));
  }
  return (
    <FileTrigger {...rest} onSelect={handleOnSelect}>
      {children || (
        <Button variant="hollow" className="bg-background">
          {`Select file${rest.allowsMultiple ? "s" : ""}`}
        </Button>
      )}
      {files?.map(({ name }, i) => (
        <p key={name + i} className="mt-1 ml-2">
          {name}
        </p>
      ))}
    </FileTrigger>
  );
}
