import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ElementProps, Menu, Space } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../api";
import { UserOrganization } from "../../apiQueries/users";
import { appHref } from "../../app/appHref";
import { orgPath, useCurrentOrgIdWithFallback } from "../../state/useOrg";
import useUser from "../../state/useUser";

const Label = (props: ElementProps<typeof Menu.Label>) => (
  <Menu.Label p="xs" fz="xs" fw="bold" c="black" {...props} />
);
const commonItemProps = { p: "xs", c: "gray.8", fw: "normal" };
const Item = (props: ElementProps<typeof Menu.Item<"div">>) => {
  return <Menu.Item component="div" {...commonItemProps} {...props} />;
};
const LinkItem = (props: ElementProps<typeof Menu.Item<"a">>) => {
  return <Menu.Item component="a" {...commonItemProps} {...props} />;
};
const Divider = () => <Menu.Divider />;

export type OrgDropdownMenuProps = { forceOpen?: boolean };
export default function OrgDropdownMenu({
  forceOpen,
}: OrgDropdownMenuProps): React.JSX.Element {
  const { user } = useUser();

  const currentOrgId = useCurrentOrgIdWithFallback();
  const { data: currentOrg } = useQuery({
    queryKey: ["org", currentOrgId],
    queryFn: () => api.getOrganization({ id: currentOrgId ?? "" }),
    enabled: !!currentOrgId,
  });
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  return (
    <Menu.Dropdown w="250">
      <Label>{currentOrg?.name}</Label>
      {(user?.userOrganizations?.length ?? -1) > 1 && (
        <>
          <Menu shadow="sm" position="right" withArrow opened={forceOpen}>
            <Menu.Target>
              <div>
                <Item rightSection={<FontAwesomeIcon icon={faChevronRight} />}>
                  Switch Organization
                </Item>
              </div>
            </Menu.Target>
            <Menu.Dropdown w="250">
              {user?.userOrganizations.map((userOrg: UserOrganization) => (
                <Item
                  onClick={() => {
                    console.log(
                      "Navigating to",
                      orgPath(currentPath, userOrg.organization.id.toString()),
                    );
                    navigate(
                      orgPath(currentPath, userOrg.organization.id.toString()),
                    );
                  }}
                  key={userOrg.organization.id}
                >
                  {userOrg.organization.name}
                </Item>
              ))}
            </Menu.Dropdown>
          </Menu>
          <Divider />
        </>
      )}
      <LinkItem
        href={appHref(
          orgPath("/manage-org/aws-connection", currentOrgId ?? ""),
        )}
      >
        Org Settings
      </LinkItem>
      <Divider />
      <LinkItem
        href={appHref(orgPath("/manage-org/members", currentOrgId ?? ""))}
      >
        Invites
      </LinkItem>
      <Space h="48" />
      <Label>{user?.name}</Label>
      <LinkItem href={appHref("/logout")}>Logout</LinkItem>
    </Menu.Dropdown>
  );
}
