import {
  faCircle,
  faCircleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Paper, SimpleGrid, Text } from "@mantine/core";
import { DiscountDiscrepancy } from "../../apiQueries/reconciliation";
import { formatUSD } from "../../modules/formatters";
import Stack from "../uikit/Stack";

type CellProps = { label: string; value: string; grayOut?: boolean };
const Cell: React.FC<CellProps> = ({ label, value, grayOut }) => {
  return (
    <Stack gap={2} style={{ opacity: grayOut ? 0.2 : 1 }}>
      <Box fz="md" fw="normal">
        {value}
      </Box>
      <Box fz="xs" fw="bold" tt="uppercase">
        {label}
      </Box>
    </Stack>
  );
};

const DiscrepancyLine: React.FC<{
  amazonNetCost: number;
  expectedCost: number;
}> = ({ amazonNetCost, expectedCost }) => {
  const discrepancyPercent = (amazonNetCost / expectedCost) * 100 - 100;

  const isIncrease = discrepancyPercent > 0;
  const changePercent = Math.abs(discrepancyPercent);
  const icon = (
    <Text span c={isIncrease ? "orange.4" : "teal.3"}>
      <FontAwesomeIcon icon={faCircle} />
    </Text>
  );
  const message = `${changePercent.toFixed(
    changePercent > 10 ? 0 : changePercent > 1 ? 1 : 2,
  )}% ${isIncrease ? "more" : "less"} than expected cost`;

  return (
    <Box bg={isIncrease ? "red.1" : "green.1"} p="sm">
      {icon} {message}
    </Box>
  );
};

const MapCreditsLine: () => React.ReactElement = () => {
  return (
    <Box bg="gray.1" p="sm" lh="sm">
      <Text span c={"gray.5"}>
        <FontAwesomeIcon icon={faCircleExclamation} />
      </Text>{" "}
      Because some of this usage was covered by MAP credits, we cannot currently
      accurately calculate the expected cost.
    </Box>
  );
};

type RegionLineProps = { discountDiscrepancy: DiscountDiscrepancy };
export const RegionLine: React.FC<RegionLineProps> = ({
  discountDiscrepancy,
}) => {
  const {
    usageAmount,
    productRegionCode,
    amazonNetCost,
    expectedCost,
    retailCost,
    pricingUnit,
    hasDiscrepancy,
    hasMapCredits,
  } = discountDiscrepancy;
  const maximumFractionDigits =
    usageAmount < 1 ? undefined : usageAmount < 100 ? 2 : 0;
  const usageAmountString = usageAmount.toLocaleString(undefined, {
    maximumFractionDigits,
  });
  return (
    <Paper withBorder shadow="none" style={{ minWidth: "700px" }}>
      <Box p="sm">
        <SimpleGrid cols={5} spacing="md">
          <Cell label="Region" value={productRegionCode} />
          <Cell label="Actual Cost" value={formatUSD(amazonNetCost)} />
          <Cell
            label="Expected Cost"
            value={formatUSD(expectedCost)}
            grayOut={hasMapCredits}
          />
          <Cell label="Retail Cost" value={formatUSD(retailCost)} />
          <Cell label="Usage" value={`${usageAmountString} ${pricingUnit}`} />
        </SimpleGrid>
      </Box>
      {hasDiscrepancy && (
        <DiscrepancyLine
          amazonNetCost={amazonNetCost}
          expectedCost={expectedCost}
        />
      )}
      {hasMapCredits && <MapCreditsLine />}
    </Paper>
  );
};
