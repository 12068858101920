import { Box } from "@mantine/core";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../api";
import TextField from "../components/uikit/TextField.jsx";
import useFormSubmitter from "../state/useFormSubmitter.js";
import AuthLayout from "./auth/AuthLayout.jsx";

export default function RegisterPage() {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { prepareSubmit, handleSubmitError } = useFormSubmitter();
  const invitation = locationState?.invitation;
  const [state, setState] = useState({
    name: invitation?.name || "",
    email: invitation?.email || "",
    password: "",
  });

  function handleSubmit(e: React.FormEvent) {
    prepareSubmit(e);
    api
      .register(state)
      .then(() => navigate("/account-created"))
      .catch(handleSubmitError);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  return (
    <AuthLayout
      heading={
        invitation ? (
          <span>
            {invitation.organizationName} invited you
            <br />
            to sign up for Skyway.
          </span>
        ) : (
          "Create your Skyway Account."
        )
      }
      formButtonProps={{ submit: "Create Account" }}
      footerLink={
        <>
          <Link to="/">Or, sign in to an existing account.</Link>
          <Box mt="md" fz="xs" opacity={0.7} lh="14px">
            By registering, you agree <br />
            to our <Link to="https://skyway.cloud/terms">Terms of Use</Link>
          </Box>
        </>
      }
      onSubmit={handleSubmit}
    >
      <TextField
        name="name"
        value={state.name}
        label="Name"
        wide
        type="name"
        placeholder="Name"
        autoComplete="name"
        onChange={handleChange}
      />
      <TextField
        name="email"
        value={state.email}
        label="Email address"
        wide
        marginTop
        type="email"
        placeholder="Email"
        autoComplete="email"
        readOnly={Boolean(invitation)}
        onChange={handleChange}
      />
      <TextField
        name="password"
        value={state.password}
        label="Password"
        wide
        marginTop
        type="password"
        placeholder="Password"
        autoComplete="new-password"
        onChange={handleChange}
      />
    </AuthLayout>
  );
}
