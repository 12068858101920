import { AppShell, Box, Group, Image } from "@mantine/core";
import React from "react";
import skywayLogo from "../../assets/images/skyway_logo.svg";

export default function HeaderV2(): React.JSX.Element {
  return (
    <AppShell.Header>
      <a href="/" style={{ textDecoration: "none" }}>
        <Group h="100%" w="200" px="lg" gap="sm">
          <Image src={skywayLogo} alt="Skyway logo" h="32" />
          <Box fw="bold">Skyway</Box>
        </Group>
      </a>
    </AppShell.Header>
  );
}
