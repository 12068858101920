import { AxiosResponse } from "axios";
import { getDashboard } from "./apiQueries/dashboards";
import { getOrganizationMembers } from "./apiQueries/organizationMembers";
import {
  getOrganization,
  getSupervisedOrganizations,
} from "./apiQueries/organizations";
import {
  getReconciliationAvailableMonths,
  getReconciliationDiscountDiscrepancies,
} from "./apiQueries/reconciliation";
import { getMe } from "./apiQueries/users";
import config from "./app/config";
import apiBase from "./modules/apiBase";
import { getSpendTrailing } from "./apiQueries/spend";

// This instance is deprecated and should be removed in the future.  Use the
// `apiRequest` object instead.  See how getReconciliationDiscountDiscrepancies
// is set up as an example.
const instance = apiBase.create(config.apiHost, {
  debug: config.debug,
  chaos: false,
});

const get = <T>(
  path: string,
  params: object,
  opts?: object,
): Promise<AxiosResponse<T>> => {
  return instance.get(path, apiBase.mergeParams(params, opts));
};
const post = (path: string, params: object, opts?: object) => {
  return instance.post(path, params, opts);
};
const patch = (path: string, params: object, opts?: object) => {
  return instance.patch(path, params, opts);
};

const put = (path: string, params: object, opts?: object) => {
  return instance.put(path, params, opts);
};

const del = (path: string, params: object, opts: object | undefined) => {
  return instance.delete(path, apiBase.mergeParams(params, opts));
};

export default {
  ...apiBase,
  axios: instance,
  get,
  post,
  patch,
  put,
  del,
  getMe,
  getDashboard,
  register: (data: any) => post(`/api/v1/auth/register`, data),
  login: (data: any) => post(`/api/v1/auth/login`, data),
  verifyEmail: (data: any) => post(`/api/v1/auth/verify_email`, data),
  forgotPassword: (data: any) => post(`/api/v1/auth/forgot_password`, data),
  resetPassword: (data: any) => post(`/api/v1/auth/reset_password/email`, data),
  getInvitation: (data: any) =>
    get(`/api/v1/auth/invitations/${data.id}`, data),
  logout: (data?: any) => post(`/api/v1/auth/logout`, data),

  uploadFile: (data: any) =>
    post(`/api/v1/organizations/${data.orgId}/uploads`, data),

  updateOrganization: (data: any) =>
    post(`/api/v1/organizations/${data.orgId}`, data),
  getOrganizationMembers,
  changeOrganizationMemberRole: ({ userId, ...data }: any) =>
    post(
      `/api/v1/organizations/${data.orgId}/members/${userId}/change_role`,
      data,
    ),
  removeOrganizationMember: ({ userId, ...data }: any) =>
    post(`/api/v1/organizations/${data.orgId}/members/${userId}/remove`, data),
  inviteOrganizationMember: (data: any) =>
    post(`/api/v1/organizations/${data.orgId}/members/invite`, data),
  reinviteOrganizationMember: (data: any) =>
    post(`/api/v1/organizations/${data.orgId}/members/reinvite`, data),

  getAwsConnections: (data: any) =>
    get(`/api/v1/organizations/${data.orgId}/aws_connections`, data),
  updateAwsConnection: (data: any) =>
    post(
      `/api/v1/organizations/${data.orgId}/aws_connections/${data.id}`,
      data,
    ),
  verifyAwsConnection: (data: any) =>
    post(
      `/api/v1/organizations/${data.orgId}/aws_connections/${data.id}/verify`,
      data,
    ),

  getContractDashboard: (data: any) =>
    get(`/api/v1/organizations/${data.orgId}/contracts/dashboard`, data),
  getContractDashboardOrgwideSpendHistoryChart: (data: any) =>
    get(
      `/api/v1/organizations/${data.orgId}/contracts/dashboard/orgwide_spend_history_chart`,
      data,
    ),
  getContractDashboardEffectiveDiscountRateChart: (data: any) =>
    get(
      `/api/v1/organizations/${data.orgId}/contracts/dashboard/effective_discount_rate_chart`,
      data,
    ),
  getContractDashboardEffectiveDiscountRate: (data: any) =>
    get(
      `/api/v1/organizations/${data.orgId}/contracts/dashboard/effective_discount_rate`,
      data,
    ),
  getContractDashboardPeriodSpendChart: (data: any) =>
    get(
      `/api/v1/organizations/${data.orgId}/contracts/dashboard/period_spend_chart`,
      data,
    ),
  getOrganizationContractCommitments: (data: any) =>
    get(`/api/v1/organizations/${data.orgId}/contract_commitments`, data),
  getCommitmentsRelatedToCommitment: (data: any) =>
    get(
      `/api/v1/organizations/${data.orgId}/commitments_related_to_commitment`,
      data,
    ),
  getCommitmentsRelatedToContract: (data: any) =>
    get(
      `/api/v1/organizations/${data.orgId}/commitments_related_to_contract`,
      data,
    ),
  getContractCommitment: (data: any) =>
    get(`/api/v1/organizations/${data.orgId}/${data.type}/${data.id}`, data),
  getContractCommitmentHistoryChart: (data: any) =>
    get(
      `/api/v1/organizations/${data.orgId}/${data.type}/${data.id}/history_chart`,
      data,
    ),
  getContracts: (data: any) =>
    get(`/api/v1/organizations/${data.orgId}/contracts`, data),
  createContract: (data: any) =>
    post(`/api/v1/organizations/${data.orgId}/contracts`, data),
  getContract: (data: any) =>
    get(`/api/v1/organizations/${data.orgId}/contracts/${data.id}`, data),

  getReconciliationDiscountDiscrepancies,
  getReconciliationAvailableMonths,

  joinOrganization: ({ id, ...data }: any) =>
    post(`/api/v1/organizations/${id}/join`, data),
  getOrganization,
  getSupervisedOrganizations,
  getSpendTrailing,
};
