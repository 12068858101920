import { apiRequest } from "../modules/apiRequest";

type DashboardIssue = {
  key: string;
  message: string;
  cta: string;
  url: string;
};
type Dashboard = { issues: DashboardIssue[] };
export const getDashboard = ({ orgId }: { orgId: string | undefined }) => {
  if (orgId) {
    return apiRequest.get<Dashboard>(
      `/api/v1/organizations/${orgId}/dashboard`,
    );
  } else {
    return apiRequest.get<Dashboard>(`/api/v1/dashboard`);
  }
};
