import { useState } from "react";
import api from "../api";
import { RelLink } from "../components/uikit/links";
import TextField from "../components/uikit/TextField.jsx";
import useFormSubmitter from "../state/useFormSubmitter.js";
import useQueryParams from "../state/useQueryParams";
import AuthLayout from "./auth/AuthLayout.jsx";

export default function ForgotPasswordPage() {
  const [state, setState] = useState({});
  const [sent, setSent] = useState(false);
  const query = useQueryParams();
  const { prepareSubmit, showErrorToast, turnOffScreenLoader } =
    useFormSubmitter();

  function handleSubmit(e) {
    prepareSubmit(e);
    api
      .forgotPassword(state)
      .then(() => setSent(true))
      .catch(showErrorToast)
      .finally(turnOffScreenLoader);
  }

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  return (
    <AuthLayout
      heading="Reset your Password"
      formButtonProps={{
        successMessage: sent && "Please check your email.",
        errorMessage:
          query.get("verified") === "false" &&
          "That code is incorrect. Reset your password to send a new code.",
      }}
      onSubmit={handleSubmit}
      footerLink={<RelLink href="/">Back to signin.</RelLink>}
    >
      <TextField
        name="email"
        label="Email address"
        type="email"
        placeholder="Enter email"
        autoComplete="email"
        wide
        onChange={handleChange}
      />
    </AuthLayout>
  );
}
