import { MantineProvider } from "@mantine/core";
import { QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import Toaster from "../components/uikit/Toaster.jsx";
import "../modules/dayConfig.js";
import { queryClient } from "../modules/queryClient.js";
import GlobalViewStateProvider from "../state/GlobalViewStateProvider.jsx";
import { ScreenLoaderProvider } from "../state/ScreenLoaderProvider.jsx";
import { UserProvider } from "../state/UserProvider.jsx";
import { AppRouter } from "./AppRouter.js";
import { theme } from "./theme.js";

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalViewStateProvider>
        <Toaster />
        <UserProvider>
          <ScreenLoaderProvider>
            <HelmetProvider>
              <MantineProvider theme={theme}>
                <AppRouter />
              </MantineProvider>
            </HelmetProvider>
          </ScreenLoaderProvider>
        </UserProvider>
      </GlobalViewStateProvider>
    </QueryClientProvider>
  );
}
