import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import "./assets/styles/content.css";
import "./assets/styles/main.css";
import "./assets/styles/reset.css";
import "./assets/styles/typography.css";
import "./assets/styles/utilities.css";

const root = document.getElementById("root");
if (!root) throw new Error("Root element not found");
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
