import { faCircleInfo, faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Group,
  Paper,
  Radio,
  Stack,
  Switch,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { MonthPicker } from "@mantine/dates";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";

import api from "../../../api";
import ErrorScreen from "../../../components/ErrorScreen";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import ScreenLoader from "../../../components/uikit/ScreenLoader";
import { useCurrentOrgIdWithFallback } from "../../../state/useOrg";

type ReportOptions = {
  includeLinkedAccounts: boolean;
  includePrivatePricing: boolean;
};

type MonthlySummaryPagePresentationProps = {
  availableMonths: string[];
  dateRange: [Date | null, Date | null];
  setDateRange: (range: [Date | null, Date | null]) => void;
  reportOptions: ReportOptions;
  setReportOptions: (options: ReportOptions) => void;
  orgId: string;
};

const ReportControls = ({
  reportOptions,
  onOptionsChange,
}: {
  reportOptions: ReportOptions;
  onOptionsChange: (options: ReportOptions) => void;
}): React.JSX.Element => (
  <Stack gap="md">
    <Group align="center">
      <Radio.Group
        value={reportOptions.includeLinkedAccounts ? "linked" : "payer"}
        onChange={(value) =>
          onOptionsChange({
            ...reportOptions,
            includeLinkedAccounts: value === "linked",
          })
        }
      >
        <Group mt="xs">
          <Radio value="payer" label="Payer only" />
          <Radio value="linked" label="Include linked accounts" />
        </Group>
      </Radio.Group>
      <Tooltip label="Choose how to aggregate the account data">
        <FontAwesomeIcon
          icon={faCircleInfo}
          style={{ color: "var(--mantine-color-gray-6)" }}
        />
      </Tooltip>
    </Group>

    <Group align="center">
      <Switch
        label="Private pricing breakout"
        checked={reportOptions.includePrivatePricing}
        onChange={(event) =>
          onOptionsChange({
            ...reportOptions,
            includePrivatePricing: event.currentTarget.checked,
          })
        }
      />
      <Tooltip label="Include private pricing details in the report">
        <FontAwesomeIcon
          icon={faCircleInfo}
          style={{ color: "var(--mantine-color-gray-6)" }}
        />
      </Tooltip>
    </Group>
  </Stack>
);

const constructDownloadUrl = (
  startDate: Date,
  endDate: Date,
  options: ReportOptions,
  orgId: string,
) => {
  return `${api.axios.getUri() ?? ""}/api/v1/organizations/${orgId}/reconciliation/monthly_comparison/csv?${new URLSearchParams(
    {
      aggregation: options.includeLinkedAccounts
        ? options.includePrivatePricing
          ? "linked-private"
          : "linked"
        : options.includePrivatePricing
          ? "payer-private"
          : "payer",
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0],
    },
  )}`;
};

export function MonthlySummaryPagePresentation({
  availableMonths,
  dateRange,
  setDateRange,
  reportOptions,
  setReportOptions,
  orgId,
}: MonthlySummaryPagePresentationProps): React.JSX.Element {
  const availableDates = availableMonths.map((dateStr) => {
    const [year, month] = dateStr.split("-").map(Number);
    return new Date(year, month - 1); // subtracting one from the month because 0 = January
  });

  const minDate = availableDates[0];
  const maxDate = availableDates[availableDates.length - 1];

  const [startDate, endDate] = dateRange;
  const isDateRangeValid = startDate && endDate;

  const downloadUrl = isDateRangeValid
    ? constructDownloadUrl(startDate, endDate, reportOptions, orgId)
    : "";

  return (
    <LayoutV2>
      <Box className="reconciliation-page-layout">
        <Box p="lg">
          <Title>Monthly Summary</Title>
          <Text c="dimmed" mt="sm" mb="lg">
            Please select the month range and a service to get focused analytic
            report.
          </Text>

          <Paper p="md" bg="gray.0">
            <Paper p="xl" shadow="sm">
              <Stack gap="xl">
                <Box>
                  <Text size="sm" fw={500} mb="xs">
                    Month Range
                  </Text>
                  <MonthPicker
                    type="range"
                    value={dateRange}
                    onChange={setDateRange}
                    minDate={minDate}
                    maxDate={maxDate}
                    defaultDate={maxDate}
                  />
                </Box>

                <ReportControls
                  reportOptions={reportOptions}
                  onOptionsChange={setReportOptions}
                />

                <Box>
                  {isDateRangeValid ? (
                    <Button
                      component="a"
                      href={downloadUrl}
                      download="monthly_summary.csv"
                      size="md"
                      variant="filled"
                      color="blue"
                      leftSection={<FontAwesomeIcon icon={faDownload} />}
                    >
                      Download Report
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      variant="filled"
                      color="blue"
                      disabled
                      leftSection={<FontAwesomeIcon icon={faDownload} />}
                    >
                      Download Report
                    </Button>
                  )}
                </Box>
              </Stack>
            </Paper>
          </Paper>
        </Box>
      </Box>
    </LayoutV2>
  );
}

export default function MonthlySummaryPage(): React.JSX.Element {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    includeLinkedAccounts: false,
    includePrivatePricing: false,
  });
  const orgId = useCurrentOrgIdWithFallback();

  const { data: availableMonths, error: availableMonthsError } = useQuery({
    queryKey: ["reconciliation_available_months"],
    queryFn: () => api.getReconciliationAvailableMonths({ orgId: orgId ?? "" }),
    enabled: !!orgId,
  });

  if (availableMonthsError) {
    return <ErrorScreen />;
  }

  if (!availableMonths || !orgId) {
    return <ScreenLoader />;
  }

  return (
    <MonthlySummaryPagePresentation
      availableMonths={availableMonths.availableMonths}
      dateRange={dateRange}
      setDateRange={setDateRange}
      reportOptions={reportOptions}
      setReportOptions={setReportOptions}
      orgId={orgId}
    />
  );
}
