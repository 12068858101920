import { useNavigate } from "react-router-dom";
import api from "../api";
import Layout from "../components/Layout";
import ScreenLoader from "../components/uikit/ScreenLoader.tsx";
import useMountEffect from "../state/useMountEffect";
import useQueryParams from "../state/useQueryParams";

export default function VerifyEmailPage() {
  const query = useQueryParams();
  const navigate = useNavigate();
  useMountEffect(() => {
    const token = query.get("token");
    api
      .verifyEmail({ token })
      .then(() => navigate(`/?verified=true`))
      .catch(() => navigate(`/forgot-password?verified=false`));
  });
  return (
    <Layout noNav>
      <ScreenLoader show />
    </Layout>
  );
}
